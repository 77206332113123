import * as React from 'react';
import Layout from '../../components/layout/Layout';
import Navbar from '../../components/navbar/navbar';
import SEO from '../../components/seo/seo';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';
import { SegmentProvider } from '../../components/analytics/SegmentProvider';
import { withIntl } from '../../i18n';

class Imprint extends React.Component<{ data: any }> {
  private analytics: SegmentProvider

  constructor(props) {
    super(props)
    this.analytics = new SegmentProvider()
  }

  componentDidMount() {
    this.analytics.trackPageView()
  }

  render() {
    return <Layout>
        <SEO />
        <Navbar light={false} />
        <div className="mdc-layout-grid cm-content--min-height">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell--span-8">
              <h1 className="mdc-typography--headline1">
                <FormattedMessage id="imprint.title" defaultMessage="Impressum" />
              </h1>
              <span className="mdc-typography--subtitle1">
                <FormattedMessage id="imprint.subtitle" defaultMessage="Angaben gem. §5 TMG" />
              </span>
              <h2 className="mdc-typography--headline2">
                <FormattedMessage id="imprint.legalEntity" defaultMessage="Betreiber und Kontakt" />
              </h2>
              <p className="mdc-typography--body1">
                {this.props.data.site.siteMetadata.legalName}
                <br />
                {this.props.data.site.siteMetadata.address.streetAddress}
                <br />
                {this.props.data.site.siteMetadata.address.postalCode} {this.props.data.site.siteMetadata.address.addressRegion}
                <br />
                {this.props.data.site.siteMetadata.telephone}
                <br />
                {this.props.data.site.siteMetadata.email}
              </p>
              <h2 className="mdc-typography--headline2">
                <FormattedMessage id="imprint.representation" defaultMessage="Vertretung" />
              </h2>
              <p className="mdc-typography--body1">
                {this.props.data.site.siteMetadata.legalName} <FormattedMessage id="imprint.representedBy" defaultMessage="wird vertreten durch" /> {this.props.data.site.siteMetadata.legalRepresentative}
              </p>
              <h2 className="mdc-typography--headline2">
                <FormattedMessage id="imprint.register" defaultMessage="Handelsregister" />
              </h2>
              <p className="mdc-typography--body1">
                {this.props.data.site.siteMetadata.register}
                <br />
                {this.props.data.site.siteMetadata.registerNumber}
              </p>
              <h2 className="mdc-typography--headline2">
                <FormattedMessage id="imprint.vatId" defaultMessage="USt-IdNr." />
              </h2>
              <p className="mdc-typography--body1">{this.props.data.site.siteMetadata.vatId}</p>
              <h2 className="mdc-typography--headline2">
                <FormattedMessage id="imprint.responsibleFor" defaultMessage="Verantwortlicher für journalistisch-redaktionelle Inhalte gem. § 55 II RstV" />
              </h2>
              <p className="mdc-typography--body1">{this.props.data.site.siteMetadata.founders[0].name}</p>
            </div>
          </div>
        </div>
      </Layout>
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        legalName
        address {
          addressRegion
          postalCode
          streetAddress
        }
        telephone
        email
        register
        registerNumber
        vatId
        founders {
          name
        }
        legalRepresentative
      }
    }
  }
`

export default withIntl(Imprint)
